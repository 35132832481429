<template>
  <div id="userAccessPage">
    <v-container>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-snackbar
        v-model="message.snackbar"
        :timeout="message.snackbarTimeout"
        bottom
      >
        {{ message.snackbarText }}
        <v-btn dark text @click="message.snackbar = false">Close</v-btn>
      </v-snackbar>

      <v-dialog v-model="createUserAccessDialog" scrollable max-width="800px">
        <v-card>
          <v-card-title>Create user access</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="createForm">
              <v-text-field
                v-model="userAccessForm.name"
                :rules="[rules.required]"
                label="Name"
                required
              ></v-text-field>
              <v-text-field
                v-model="userAccessForm.email"
                :rules="[rules.required, rules.email]"
                label="Email"
                required
              ></v-text-field>
              <v-text-field
                v-model="userAccessForm.accessPassword"
                :rules="[rules.required]"
                label="Access password"
                required
              ></v-text-field>
              <v-text-field
                v-model="userAccessForm.location"
                :rules="[rules.required]"
                label="Location"
                required
              ></v-text-field>
              <v-text-field
                v-model="userAccessForm.department"
                :rules="[rules.required]"
                label="Department"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="createUserAccessDialog = false"
              class="hp--btn"
              >Close</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="onCreateUserAccessPage()"
              class="hp--btn"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="updateUserAccessDialog" scrollable max-width="500px">
        <v-card>
          <v-card-title>Update user access</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="updateForm">
              <v-text-field
                v-model="userAccessForm.name"
                :rules="[rules.required]"
                label="Name"
                required
              ></v-text-field>
              <v-text-field
                v-model="userAccessForm.email"
                :rules="[rules.required, rules.email]"
                label="Email"
                required
              ></v-text-field>
              <v-text-field
                v-model="userAccessForm.accessPassword"
                :rules="[rules.required]"
                label="Access password"
                required
              ></v-text-field>
              <v-text-field
                v-model="userAccessForm.location"
                :rules="[rules.required]"
                label="Location"
                required
              ></v-text-field>
              <v-text-field
                v-model="userAccessForm.department"
                :rules="[rules.required]"
                label="Department"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="updateUserAccessDialog = false"
              class="hp--btn"
              >Close</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="onUpdateUserAccessPage()"
              class="hp--btn"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title class="grey--text">User Access</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  dark
                  small
                  absolute
                  bottom
                  left
                  fab
                  v-on="on"
                  @click="
                    userAccessForm = [];
                    createUserAccessDialog = !createUserAccessDialog;
                  "
                  class="hp--btn"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>New user access</span>
            </v-tooltip> -->
          </v-toolbar>
          <v-divider></v-divider>

          <v-card-text>
            <v-data-table
              :items="userAccess"
              :headers="userAccessTableHeaders"
              class="elevation-1"
              page.sync="0"
              :items-per-page="-1"
              :search="search"
              sort-by="['company']"
            >
              <template v-slot:item.action="{ item }">
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="success"
                      @click="updateUserAccess(item)"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <span>Update user access</span>
                </v-tooltip> -->

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="deleteUserAccessPage(item)"
                      color="error"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>Delete user access</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-btn color="deep-purple darken-1" dark @click="downloadCSV()">
                Download CSV
                <v-icon right dark>mdi-file-excel</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import * as UserAccess from "./../classes/userAccess";
export default {
  data() {
    return {
      loader: false,
      message: {
        snackbar: false,
        snackbarText: "",
        snackbarTimeout: 3000,
      },
      search: "",
      userAccess: [],
      userAccessForm: [],
      userAccessTableHeaders: [
        {
          text: "Company",
          align: "left",
          value: "company",
        },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Location", value: "location" },
        { text: "Department", value: "department" },
        { value: "action", align: "right", sortable: false, width: 100 },
      ],
      rules: {
        required: (v) => !!v || "This field is required.",
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      },
      createUserAccessDialog: false,
      updateUserAccessDialog: false,
    };
  },
  created() {
    const that = this;
    this.loader = true;

    UserAccess.getAllUsersAccess().then((users) => {
      users.forEach(function (userAccess) {
        userAccess.company = userAccess.campaignManager.company
          ? userAccess.campaignManager.company
          : "";
        delete userAccess.campaignManager;
        that.userAccess.push(userAccess);
      });
    });
    this.loader = false;
  },
  methods: {
    onCreateUserAccessPage() {
      if (this.$refs.createForm.validate()) {
        UserAccess.createUserAccess(this.userAccessForm).then((result) => {
          this.userAccess.push(result);
          this.createUserAccessDialog = false;
          this.showSuccessMessage(`New video page ${result.name} added`);
        });
      }
    },
    onUpdateUserAccessPage() {
      if (this.$refs.updateForm.validate()) {
        UserAccess.updateUserAccess(this.userAccessForm).then((result) => {
          this.userAccess.forEach(function (userAccess) {
            if (userAccess.id === result.id) {
              userAccess.name = result.name;
              userAccess.email = result.email;
              userAccess.accessPassword = result.accessPassword;
              userAccess.location = result.location;
              userAccess.department = result.department;
            }
          });

          this.updateUserAccessDialog = false;
          this.showSuccessMessage(`Video page ${result.name} updated`);
        });
      }
    },
    updateUserAccess(item) {
      UserAccess.getUserAccessById(item.id).then((userAccess) => {
        this.userAccessForm = {
          id: userAccess.id,
          name: userAccess.name,
          email: userAccess.email,
          accessPassword: userAccess.accessPassword,
          location: userAccess.location,
          department: userAccess.department,
        };
        this.updateUserAccessDialog = true;
      });
    },
    deleteUserAccessPage(item) {
      const that = this;
      confirm("Are you sure you want to delete?") &&
        UserAccess.deleteUserAccess(item.id).then((result) => {
          that.userAccess = that.userAccess.filter(function (video) {
            return video.id !== result.id;
          });
          this.showSuccessMessage(`Video page ${result.name} deleted`);
        });
    },
    showSuccessMessage(message) {
      this.message.snackbarText = message;
      this.message.snackbar = true;
    },
    csvExport() {
      const arrData = this.userAccess;
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(","),
        ...arrData.map((item) => Object.values(item).join(",")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    downloadCSV() {
      this.csvExport();
    },
  },
};
</script>