<template>
  <div id="videoMangerPage">
    <v-container>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-snackbar
        v-model="message.snackbar"
        :timeout="message.snackbarTimeout"
        bottom
      >
        {{ message.snackbarText }}
        <v-btn dark text @click="message.snackbar = false">Close</v-btn>
      </v-snackbar>

      <v-dialog v-model="createVideoManagerDialog" scrollable max-width="700px">
        <v-card>
          <v-card-title>Create video page</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="createForm">
              <v-text-field
                v-model="videoManagerForm.title"
                :rules="[rules.required]"
                label="Title"
                required
              ></v-text-field>
              <v-textarea
                v-model="videoManagerForm.introduction"
                :rules="[rules.required]"
                label="Introduction"
                required
              ></v-textarea>
              <v-combobox
                v-model="videoManagerForm.codes"
                :items="vimeoIds"
                label="Vimeo codes"
                multiple
                chips
              ></v-combobox>
              <v-text-field
                v-model="videoManagerForm.outro"
                label="Outro"
              ></v-text-field>
              <v-text-field
                v-model="videoManagerForm.pdf"
                label="PDF"
              ></v-text-field>
              <v-file-input
                v-model="videoManagerForm.pdfFileName"
                show-size
                label="File"
              ></v-file-input>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="createVideoManagerDialog = false"
              class="hp--btn"
              >Close</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="onCreateVideoPage()"
              class="hp--btn"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="updateVideoManagerDialog" scrollable max-width="700px">
        <v-card>
          <v-card-title>Update video page</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="updateForm">
              <v-text-field
                v-model="videoManagerForm.title"
                :rules="[rules.required]"
                label="Title"
                required
              ></v-text-field>
              <v-textarea
                v-model="videoManagerForm.introduction"
                :rules="[rules.required]"
                label="Introduction"
                required
              ></v-textarea>
              <!-- <v-text-field
                v-model="videoManagerForm.introduction"
                :rules="[rules.required]"
                label="Introduction"
                required
              ></v-text-field> -->
              <v-combobox
                v-model="videoManagerForm.codes"
                :items="vimeoIds"
                label="Vimeo codes"
                multiple
                chips
              ></v-combobox>
              <v-text-field
                v-model="videoManagerForm.outro"
                label="Outro"
                required
              ></v-text-field>
              <v-text-field
                v-model="videoManagerForm.pdf"
                label="PDF"
                required
              ></v-text-field>
              <v-file-input
                v-model="videoManagerForm.pdfFileName"
                show-size
                label="File"
              ></v-file-input>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="updateVideoManagerDialog = false"
              class="hp--btn"
              >Close</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="onUpdateVideoPage()"
              class="hp--btn"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title class="grey--text"
              >Video Page Manager</v-toolbar-title
            >
            <div class="flex-grow-1"></div>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  dark
                  small
                  absolute
                  bottom
                  left
                  fab
                  v-on="on"
                  @click="
                    videoManagerForm = [];
                    createVideoManagerDialog = !createVideoManagerDialog;
                  "
                  class="hp--btn"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>New</span>
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>

          <v-card-text>
            <v-data-table
              :items="videos"
              :headers="videosTableHeaders"
              class="elevation-1"
              page.sync="0"
              :items-per-page="-1"
              :search="search"
              sort-by="['name']"
            >
              <template v-slot:item.codes="{ item }">
                <v-chip-group active-class="primary--text" column>
                  <v-chip v-for="code in item.codes" :key="code" small>
                    {{ code }}
                  </v-chip>
                </v-chip-group>
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="success"
                      @click="updateVideoManager(item)"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <span>Update video page</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="deleteVideoManager(item)"
                      color="error"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>Delete video page</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="viewClientAccess(item)"
                      color="secondary"
                      >mdi-group-add</v-icon
                    >
                  </template>
                  <span>Add client access</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import * as VideoPageManager from "./../classes/videoPageManager";
import * as VimeoVideoManager from "./../classes/vimeoVideoManager";
export default {
  data() {
    return {
      loader: false,
      message: {
        snackbar: false,
        snackbarText: "",
        snackbarTimeout: 3000,
      },
      search: "",
      videos: [],
      vimeoIds: [],
      videoManagerForm: [],
      videosTableHeaders: [
        {
          text: "Title",
          align: "left",
          value: "title",
        },
        { text: "Introduction", value: "introduction" },
        { text: "Outro", value: "outro" },
        { text: "Codes", value: "codes" },
        { value: "action", align: "right", sortable: false, width: 100 },
      ],
      rules: {
        required: (v) => !!v || "This field is required.",
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      },
      createVideoManagerDialog: false,
      updateVideoManagerDialog: false,
    };
  },
  created() {
    const that = this;
    this.loader = true;

    VideoPageManager.getAllVideoManagerPages().then((videos) => {
      videos.forEach(function (video) {
        let vimeoIdsObj = JSON.parse(video.codes);
        const vimeoIds = vimeoIdsObj.map((vimeo) => vimeo.value);
        that.videos.push({
          id: video.id,
          title: video.title,
          introduction: video.introduction,
          outro: video.outro,
          // codes: JSON.parse(video.codes),
          codes: vimeoIds,
          pdf: video.pdf,
          pdfFileName: video.pdfFileName,
        });
      });
    });

    VimeoVideoManager.getAllVimeoVideos().then((vimeoVideos) => {
      vimeoVideos.forEach(function (vimeoVideo) {
        that.vimeoIds.push({
          text: vimeoVideo.description,
          value: vimeoVideo.vimeoId,
        });
      });
    });
    this.loader = false;
  },
  methods: {
    onCreateVideoPage() {
      if (this.$refs.createForm.validate()) {
        VideoPageManager.createVideoManagerPage(this.videoManagerForm).then(
          (result) => {
            const vimeoIdsObj = JSON.parse(result.codes);
            const vimeoIds = vimeoIdsObj.map((vimeo) => vimeo.value);
            result.codes = vimeoIds;
            this.videos.push(result);
            this.createVideoManagerDialog = false;
            this.showSuccessMessage(`New video page ${result.title} added`);
          }
        );
      }
    },
    onUpdateVideoPage() {
      VideoPageManager.updateVideoManagerPage(this.videoManagerForm).then(
        (result) => {
          this.videos.forEach(function (v) {
            if (v.id === result.id) {
              const vimeoIdsObj = JSON.parse(result.codes);
              const vimeoIds = vimeoIdsObj.map((vimeo) => vimeo.value);
              v.title = result.title;
              v.introduction = result.introduction;
              v.outro = result.outro;
              v.codes = vimeoIds;
            }
          });
          this.updateVideoManagerDialog = false;
          this.showSuccessMessage(`Video page ${result.title} updated`);
        }
      );
    },
    updateVideoManager(item) {
      VideoPageManager.getVideoManagerById(item.id).then((video) => {
        this.videoManagerForm = {
          id: video.id,
          title: video.title,
          introduction: video.introduction,
          outro: video.outro,
          codes: JSON.parse(video.codes),
          pdf: video.pdf,
          // pdfFileName: video.pdfFileName,
        };
        this.updateVideoManagerDialog = true;
      });
    },
    deleteVideoManager(item) {
      const that = this;
      confirm("Are you sure you want to delete?") &&
        VideoPageManager.deleteVideoManager(item.id).then((result) => {
          that.videos = that.videos.filter(function (video) {
            return video.id !== result.id;
          });
          this.showSuccessMessage(`Video page ${result.title} deleted`);
        });
    },
    showSuccessMessage(message) {
      this.message.snackbarText = message;
      this.message.snackbar = true;
    },
  },
};
</script>

<style>
</style>