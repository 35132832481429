<template>
  <div id="addVideoPage">
    <v-container>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-snackbar
        v-model="message.snackbar"
        :timeout="message.snackbarTimeout"
        bottom
      >
        {{ message.snackbarText }}
        <v-btn dark text @click="message.snackbar = false">Close</v-btn>
      </v-snackbar>

      <v-dialog v-model="createAddVideoDialog" scrollable max-width="800px">
        <v-card>
          <v-card-title>Add video</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="createForm">
              <v-text-field
                v-model="addVideoForm.vimeoId"
                :rules="[rules.required]"
                label="Vimeo Id"
                required
              ></v-text-field>
              <v-text-field
                v-model="addVideoForm.description"
                :rules="[rules.required]"
                label="Description"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="createAddVideoDialog = false"
              class="hp--btn"
              >Close</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="onCreateAddVideoManager()"
              class="hp--btn"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="updateAddVideoDialog"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-card-title>Update video</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="updateForm">
              <v-text-field
                v-model="addVideoForm.vimeoId"
                :rules="[rules.required]"
                label="Vimeo Id"
                required
              ></v-text-field>
              <v-text-field
                v-model="addVideoForm.description"
                :rules="[rules.required]"
                label="Description"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="updateAddVideoDialog = false"
              class="hp--btn"
              >Close</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="onUpdateAddVideoManager()"
              class="hp--btn"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title class="grey--text"
              >Add Video</v-toolbar-title
            >
            <div class="flex-grow-1"></div>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  dark
                  small
                  absolute
                  bottom
                  left
                  fab
                  v-on="on"
                  @click="
                    addVideoForm = [];
                    createAddVideoDialog = !createAddVideoDialog;
                  "
                  class="hp--btn"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>New campaign</span>
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>

          <v-card-text>
            <v-data-table
              :items="vimeoVideos"
              :headers="campaignTableHeaders"
              class="elevation-1"
              page.sync="0"
              :items-per-page="-1"
              :search="search"
              sort-by="['company']"
            >
              <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="success"
                      @click="updateVideoManager(item)"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <span>Update video</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="deleteVideoManager(item)"
                      color="error"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>Delete video</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import * as VimeoVideoManager from "./../classes/vimeoVideoManager";
export default {
  data() {
    return {
      loader: false,
      message: {
        snackbar: false,
        snackbarText: "",
        snackbarTimeout: 3000,
      },
      search: "",
      vimeoVideos: [],
      addVideoForm: [],
      dateMenu: false,
      dateMenuUpdate: false,
      campaignTableHeaders: [
        {
          text: "Vimeo Id",
          align: "left",
          value: "vimeoId",
        },
        { text: "Description", value: "description" },
        { value: "action", align: "right", sortable: false, width: 100 },
      ],
      rules: {
        required: (v) => !!v || "This field is required.",
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      },
      createAddVideoDialog: false,
      updateAddVideoDialog: false,
    };
  },
  created() {
    const that = this;
    this.loader = true;

    VimeoVideoManager.getAllVimeoVideos().then((vimeoVideos) => {
      vimeoVideos.forEach(function (vimeoVideo) {
        that.vimeoVideos.push(vimeoVideo);
      });
    });

    this.loader = false;
  },
  methods: {
    onCreateAddVideoManager() {
      if (this.$refs.createForm.validate()) {
        VimeoVideoManager.createVimeoVideo(this.addVideoForm).then(
          (result) => {
            this.vimeoVideos.push(result);
            this.createAddVideoDialog = false;
            this.showSuccessMessage(`New vimeo video ${result.description} added`);
          }
        );
      }
    },
    onUpdateAddVideoManager() {
      if (this.$refs.updateForm.validate()) {
        VimeoVideoManager.updateVideoVimeo(this.addVideoForm).then(
          (result) => {
           this.vimeoVideos.forEach(function (video) {
              if (video.id === result.id) {
                video.id = result.id;
                video.vimeoId = result.vimeoId;
                video.description = result.description;
              }
            });

            this.updateAddVideoDialog = false;
            this.showSuccessMessage(`Vimeo video ${result.description} updated`);
          }
        );
      }
    },
    updateVideoManager(item) {
      VimeoVideoManager.getVimeoVideoById(item.id).then((video) => {
        this.addVideoForm = {
          id: video.id,
          vimeoId: video.vimeoId,
          description: video.description,
        };
        this.updateAddVideoDialog = true;
      });
    },
    deleteVideoManager(item) {
      const that = this;
      confirm("Are you sure you want to delete?") &&
        VimeoVideoManager.deleteVimeoVideo(item.id).then((result) => {
          that.vimeoVideos = that.vimeoVideos.filter(function (video) {
            return video.id !== result.id;
          });
          this.showSuccessMessage(`Video page ${result.description} deleted`);
        });
    },
    showSuccessMessage(message) {
      this.message.snackbarText = message;
      this.message.snackbar = true;
    },
  },
};
</script>