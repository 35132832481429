import { Storage, Auth } from "aws-amplify";
import Aws from "aws-sdk";
export class s3helper {
  constructor() {}

  static generateS3Key(filename) {
    let file = `${Date.now()}-${filename}`;
    file = file.replace(/\s/g, "");
    return file;
  }

  async gets3Image(key) {
    Aws.config.update({
      accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
      region: Auth._config.region,
      identityPoolId: Auth.userPool.userPoolId,
    });
    return new Promise((resolve, reject) => {
      Storage.get(key, {
        level: "public",
      }).then((result) => {
        resolve(result);
      });
    });
  }
}
