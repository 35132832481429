import { Storage } from "aws-amplify";
import * as mutations from "./../graphql/mutations";
import * as queries from "./../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
export function createVimeoVideo({ vimeoId, description }) {
  // PDF Attached
  var videoManagerOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(mutations.createVimeoVideo, {
        input: {
          vimeoId: vimeoId,
          description: description,
          isDeleted: 0,
        },
      })
    ).then((result) => {
      let video = result.data.createVimeoVideo;
      return resolve({
        id: video.id,
        vimeoId: vimeoId,
        description: description,
      })
    });
  });
  return videoManagerOutput;
}

export function updateVideoVimeo({ id, vimeoId, description }) {
  var videoManagerOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(mutations.updateVimeoVideo, {
        input: {
          id: id,
          vimeoId: vimeoId,
          description: description,
        },
      })
    ).then((result) => {
      let video = result.data.updateVimeoVideo;
      return resolve(video);
    });
  });
  return videoManagerOutput;
};

export function deleteVimeoVideo(id) {
  var videoManagerOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(mutations.deleteVimeoVideo, {
        input: {
          id: id,
        },
      })
    ).then((result) => {
      return resolve(result.data.deleteVimeoVideo);
    });
  });
  return videoManagerOutput;
}

export function getVimeoVideoById(id) {
  var videoManagerOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(queries.getVimeoVideo, {
        id: id,
      })
    ).then((result) => {
      return resolve(result.data.getVimeoVideo);
    });
  });
  return videoManagerOutput;
}

export async function getAllVimeoVideos() {
  var nextRecord = (nextToken) => {
    return new Promise(async (resolve) => {
      API.graphql(
        graphqlOperation(queries.listVimeoVideos, {
          filter: { isDeleted: { eq: 0 } },
          nextToken: nextToken,
        })
      ).then((result) => {
        return resolve(result.data.listVimeoVideos);
      });
    });
  }

  const result = await nextRecord(null);
  let nextToken = result.nextToken;
  let videoManagerOutput = result.items;
  while (nextToken !== null) {
    let nextVimeoVideos = await nextRecord(nextToken);
    videoManagerOutput.push(...nextVimeoVideos.items);
    nextToken = nextVimeoVideos.nextToken;
  }
  return videoManagerOutput;
}