import { Storage } from "aws-amplify";
import * as mutations from "./../graphql/mutations";
import * as queries from "./../graphql/queries";
import { s3helper } from "../classes/s3helper";
import { API, graphqlOperation } from "aws-amplify";
export function createVideoManagerPage({ title, introduction, codes, outro, pdf, pdfFileName }) {
  // PDF Attached
  var videoManagerOutput = new Promise(function (resolve, reject) {
    if (pdfFileName) {
      let file = pdfFileName;
      const fileName = file.name;
      const s3Key = s3helper.generateS3Key(fileName);
      Storage.put("videomanager/logo/" + s3Key, file, {
        level: "public",
        contentType: file.type,
      }).then((upload) => {
        API.graphql(
          graphqlOperation(mutations.createVideoManager, {
            input: {
              title: title,
              introduction: introduction,
              codes: JSON.stringify(codes),
              outro: outro,
              pdf: upload.key,
              pdfFileName: fileName,
              isDeleted: 0,
            },
          })
        ).then((result) => {
          let video = result.data.createVideoManager;
          return resolve({
            id: video.id,
            title: video.title,
            introduction: video.introduction,
            outro: video.outro,
            codes: video.codes,
            pdf: video.pdf,
            pdfFileName: video.pdfFileName,
          })
        });
      });
    } else {
      // No file attachment
      API.graphql(
        graphqlOperation(mutations.createVideoManager, {
          input: {
            title: title,
            introduction: introduction,
            codes: JSON.stringify(codes),
            outro: outro,
            isDeleted: 0,
          },
        })
      ).then((result) => {
        let video = result.data.createVideoManager;
        return resolve({
          id: video.id,
          title: video.title,
          introduction: video.introduction,
          outro: video.outro,
          codes: video.codes,
        });
      });
    }
  });
  return videoManagerOutput;
}

export function updateVideoManagerPage({ id, title, introduction, codes, outro, pdf, pdfFileName }) {
  var videoManagerOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(mutations.updateVideoManager, {
        input: {
          id: id,
          title: title,
          introduction: introduction,
          codes: JSON.stringify(codes),
          outro: outro,
        },
      })
    ).then((result) => {
      let video = result.data.updateVideoManager;
      return resolve(video);
    });
  });
  return videoManagerOutput;
};

export function deleteVideoManager(id) {
  var videoManagerOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(mutations.updateVideoManager, {
        input: {
          id: id,
          isDeleted: 1,
        },
      })
    ).then((result) => {
      return resolve(result.data.updateVideoManager);
    });
  });
  return videoManagerOutput;
}

export function getVideoManagerById(id) {
  var videoManagerOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(queries.getVideoManager, {
        id: id,
      })
    ).then((result) => {
      return resolve(result.data.getVideoManager);

    });
  });
  return videoManagerOutput;
}

export async function getAllVideoManagerPages() {
  var nextRecord = (nextToken) => {
    return new Promise(async (resolve) => {
      API.graphql(
        graphqlOperation(queries.listVideoManagers, {
          filter: { isDeleted: { eq: 0 } },
          nextToken: nextToken,
        })
      ).then((result) => {
        return resolve(result.data.listVideoManagers);
      });
    });
  }

  const result = await nextRecord(null);
  let nextToken = result.nextToken;
  let videoManagerOutput = result.items;
  while (nextToken !== null) {
    let nextVideoManager = await nextRecord(nextToken);
    videoManagerOutput.push(...nextVideoManager.items);
    nextToken = nextVideoManager.nextToken;
  }
  return videoManagerOutput;
}