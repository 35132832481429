<template>
  <div id="videoMangerPage">
    <v-container>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-snackbar
        v-model="message.snackbar"
        :timeout="message.snackbarTimeout"
        bottom
      >
        {{ message.snackbarText }}
        <v-btn dark text @click="message.snackbar = false">Close</v-btn>
      </v-snackbar>

      <v-dialog v-model="createVideoManagerDialog" scrollable max-width="500px">
        <v-card>
          <v-card-title>Create campaign</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="createForm">
              <v-select
                v-model="campaignManagerForm.videoManager"
                :items="videoPageManager"
                :rules="[rules.required]"
                label="Video page"
              ></v-select>
              <v-text-field
                v-model="campaignManagerForm.company"
                :rules="[rules.required]"
                label="Company"
                required
              ></v-text-field>
              <v-text-field
                v-model="campaignManagerForm.accessCode"
                :rules="[rules.required]"
                label="Access code"
                required
              ></v-text-field>
              <v-text-field
                v-model="campaignManagerForm.password"
                :rules="[rules.required]"
                label="Password"
                required
              ></v-text-field>
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="campaignManagerForm.expiryDate"
                    label="Expiry Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="campaignManagerForm.expiryDate"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-checkbox
                v-model="campaignManagerForm.showLocationDepartment"
                label="Show location and department"
              ></v-checkbox>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="createVideoManagerDialog = false"
              class="hp--btn"
              >Close</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="onCreateCampaignManager()"
              class="hp--btn"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="updateCampaignManagerDialog"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-card-title>Update campaign</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="updateForm">
              <v-select
                v-model="campaignManagerForm.videoManager"
                :items="videoPageManager"
                :rules="[rules.required]"
                label="Video page"
              ></v-select>
              <v-text-field
                v-model="campaignManagerForm.company"
                :rules="[rules.required]"
                label="Company"
                required
              ></v-text-field>
              <v-text-field
                v-model="campaignManagerForm.accessCode"
                :rules="[rules.required]"
                label="Access code"
                required
              ></v-text-field>
              <v-text-field
                v-model="campaignManagerForm.password"
                :rules="[rules.required]"
                label="Password"
                required
              ></v-text-field>
              <v-menu
                v-model="dateMenuUpdate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="campaignManagerForm.expiryDate"
                    label="Expiry Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="campaignManagerForm.expiryDate"
                  @input="dateMenuUpdate = false"
                ></v-date-picker>
              </v-menu>
              <v-checkbox
                v-model="campaignManagerForm.showLocationDepartment"
                label="Show location and department"
              ></v-checkbox>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="updateCampaignManagerDialog = false"
              class="hp--btn"
              >Close</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="onUpdateCampaignManager()"
              class="hp--btn"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title class="grey--text"
              >Campaign Manager</v-toolbar-title
            >
            <div class="flex-grow-1"></div>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  dark
                  small
                  absolute
                  bottom
                  left
                  fab
                  v-on="on"
                  @click="
                    campaignManagerForm = [];
                    createVideoManagerDialog = !createVideoManagerDialog;
                  "
                  class="hp--btn"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>New campaign</span>
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>

          <v-card-text>
            <v-data-table
              :items="campaign"
              :headers="campaignTableHeaders"
              class="elevation-1"
              page.sync="0"
              :items-per-page="-1"
              :search="search"
              sort-by="['company']"
            >
              <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="success"
                      @click="updateCampaignManager(item)"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <span>Update campaign</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="deleteCampaignManager(item)"
                      color="error"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>Delete campaign</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import * as CampaignManager from "./../classes/campaignManager";
import * as VideoPageManager from "./../classes/videoPageManager";
export default {
  data() {
    return {
      loader: false,
      message: {
        snackbar: false,
        snackbarText: "",
        snackbarTimeout: 3000,
      },
      search: "",
      campaign: [],
      campaignManagerForm: [],
      videoPageManager: [],
      dateMenu: false,
      dateMenuUpdate: false,
      campaignTableHeaders: [
        {
          text: "Company",
          align: "left",
          value: "company",
        },
        { text: "Access code", value: "accessCode" },
        { text: "Expiry date", value: "expiryDate" },
        { value: "action", align: "right", sortable: false, width: 100 },
      ],
      rules: {
        required: (v) => !!v || "This field is required.",
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      },
      createVideoManagerDialog: false,
      updateCampaignManagerDialog: false,
    };
  },
  created() {
    const that = this;
    this.loader = true;

    CampaignManager.getAllCampaigns().then((campaigns) => {
      campaigns.forEach(function (campaign) {
        that.campaign.push(campaign);
      });
    });

    VideoPageManager.getAllVideoManagerPages().then((videos) => {
      videos.forEach(function (video) {
        that.videoPageManager.push({
          text: video.title,
          value: video.id,
        });
      });
    });

    this.loader = false;
  },
  methods: {
    onCreateCampaignManager() {
      if (this.$refs.createForm.validate()) {
        CampaignManager.createCampaignManager(this.campaignManagerForm).then(
          (result) => {
            this.campaign.push(result);
            this.createVideoManagerDialog = false;
            this.showSuccessMessage(`New video page ${result.company} added`);
          }
        );
      }
    },
    onUpdateCampaignManager() {
      if (this.$refs.updateForm.validate()) {
        CampaignManager.updateCampaignManager(this.campaignManagerForm).then(
          (result) => {
            this.campaign.forEach(function (campaign) {
              if (campaign.id === result.id) {
                campaign.company = result.company;
                campaign.accessCode = result.accessCode;
                campaign.password = result.accessPassword;
                campaign.expiryDate = result.expiryDate;
                campaign.active = result.active;
              }
            });

            this.updateCampaignManagerDialog = false;
            this.showSuccessMessage(`Video page ${result.company} updated`);
          }
        );
      }
    },
    updateCampaignManager(item) {
      CampaignManager.getCampaignManagerById(item.id).then((campaign) => {
        this.campaignManagerForm = {
          id: campaign.id,
          company: campaign.company,
          accessCode: campaign.accessCode,
          password: campaign.accessPassword,
          showLocationDepartment: campaign.showLocationDepartment,
          expiryDate: campaign.expiryDate,
          active: campaign.active,
          videoManager: campaign.videoManager.id,
          // pdfFileName: video.pdfFileName,
        };
        this.updateCampaignManagerDialog = true;
      });
    },
    deleteCampaignManager(item) {
      const that = this;
      confirm("Are you sure you want to delete?") &&
        CampaignManager.deleteCampaignManager(item.id).then((result) => {
          that.campaign = that.campaign.filter(function (video) {
            return video.id !== result.id;
          });
          this.showSuccessMessage(`Video page ${result.company} deleted`);
        });
    },
    showSuccessMessage(message) {
      this.message.snackbarText = message;
      this.message.snackbar = true;
    },
  },
};
</script>